// Color Variables
$blue: #005186 !default;

// Body
$body-color: $blue;

// Typography
$font-family-sans-serif: "ff-meta-web-pro-n4","ff-meta-web-pro",sans-serif;;
//$font-family-sans-serif: 'Roboto', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;
