// Fonts
@import url('https://fonts.bunny.net/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import 'bootstrap/scss/bootstrap';

.navbar {
    background: #f1f2f4;
    border-bottom: 1px solid #dadbdd;
}
.nav-link {
    color: $body-color;
    font-weight: bold;
}
.navbar-brand {
    font-size: 24px;
    font-weight: 700;
    span:first-child {
        font-family: "mitigate-n6","mitigate",sans-serif;
        font-style: normal;
        color: $blue;
    }
    span:last-child {
        font-family: "mitigate-n6","mitigate",sans-serif;
        font-style: normal;
        color: #1999e4;
    }
}

.btn {
    padding: 1px 7px 5px;
}

.card {
    border-radius: 0;
    .card-header {
        background: $blue;
        color: #fff;
        border-radius: 0;
        font-weight: bold;
    }
}

#invoice-table {
    thead tr th {
        color: #fff;
        background: $blue;
    }
}

footer {
    min-height: 100px;
    background: #f1f2f4;
    border-top: 1px solid #dadbdd;
}

/**
 * Tooltip Styles
 */

/* Tooltip container */
.simple-tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
    cursor: help;
}

/* Tooltip text */
.simple-tooltip .tooltiptext {
    visibility: hidden;
    width: 320px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.simple-tooltip:hover .tooltiptext {
    visibility: visible;
}
